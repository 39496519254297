import React from "react";

const CTABanner = () => {
  return (
    <div className="py-8 bg-primary text-white text-center">
      <h3 className="text-2xl font-semibold mb-4">Ready to uncover the echoes of history?</h3>
      <button className="bg-white text-primary px-6 py-3 rounded-lg font-semibold shadow hover:bg-neutral-card dark:hover:bg-dark-card transition-transform transform hover:scale-105">
        Sign Up for Free
      </button>
    </div>
  );
};

export default CTABanner;
