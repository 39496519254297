import React from "react";
import HeroSection from "./components/HeroSection";
import HowItWorksCarousel from "./components/HowItWorksCarousel";
import CTABanner from "./components/CTABanner";
import UseCasesCarousel from "./components/UseCasesCarousel";
import Footer from "./components/Footer";
import ThemeToggle from "./components/ThemeToggle";

function App() {
  return (
    <div className="bg-neutral-light dark:bg-dark-background text-neutral-text dark:text-dark-text overflow-x-hidden transition-colors duration-300">
      <header className="flex justify-between items-center p-4">
        <h1 className="text-2xl cursor-default font-bold transition-transform transform hover:scale-105">echoplot.io</h1>
        {/* <ThemeToggle /> */}
      </header>
      <HeroSection />
      <HowItWorksCarousel />
      <UseCasesCarousel />
      <CTABanner />
      <Footer />
    </div>
  );
}

export default App;
