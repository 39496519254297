import React from "react";

const Footer = () => {
  return (
    <footer className="bg-neutral-card dark:bg-dark-card text-neutral-muted dark:text-dark-muted py-6 text-center">
      <p className="text-sm">
        © 2024 echoplot.io - A product of 
        <a href="https://datacorridor.io" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-light ml-1">
          DATA CORRIDOR LIMITED
        </a>.
      </p>
    </footer>
  );
};

export default Footer;
