import React from "react";
import { motion } from "framer-motion";

const HeroSection = () => {
  return (
    <div className="bg-neutral-light dark:bg-dark-background text-neutral-text dark:text-dark-text h-screen flex flex-col items-center justify-center transition-colors duration-300">
      <motion.h1
        className="text-5xl font-bold mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        Discover Places, Trace Histories
      </motion.h1>
      <motion.p
        className="text-lg mb-6 max-w-xl text-center text-neutral-muted dark:text-dark-muted"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        Explore places, uncover events, and follow the threads of time.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      >
        <button className="bg-primary text-white px-8 py-3 rounded-lg shadow hover:bg-primary-light transition-all transform hover:scale-105">
          Start Exploring
        </button>
      </motion.div>
    </div>
  );
};

export default HeroSection;
