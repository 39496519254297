import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const useCases = [
  {
    id: 1,
    title: "Travelers",
    description: "Uncover historical significance of your destinations.",
    image: "/images/travelers.jpg", // Replace with actual image paths
  },
  {
    id: 2,
    title: "Educators",
    description: "Bring lessons to life with vivid historical maps.",
    image: "/images/educators.jpg",
  },
  {
    id: 3,
    title: "Researchers",
    description: "Streamline research with a unified timeline.",
    image: "/images/researchers.jpg",
  },
];

const UseCasesCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % useCases.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-8 bg-neutral-light text-neutral-text flex flex-col items-center">
      <h2 className="text-3xl font-bold mb-8">Ideal For</h2>
      {/* <div className="relative w-full max-w-4xl h-80 overflow-hidden rounded-lg shadow">
        <AnimatePresence mode="wait">
          <motion.div
            key={useCases[currentIndex].id}
            className="absolute w-full h-full flex flex-col items-center justify-center"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.4 }}
          >
            <img
              src={useCases[currentIndex].image}
              alt={useCases[currentIndex].title}
              className="w-full h-56 object-cover rounded-t-lg"
            />
            <div className="bg-neutral-card p-6 w-full text-center rounded-b-lg">
              <h3 className="text-xl font-semibold text-primary mb-2">
                {useCases[currentIndex].title}
              </h3>
              <p className="text-neutral-muted">{useCases[currentIndex].description}</p>
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {useCases.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? "bg-primary" : "bg-neutral-muted"
              }`}
            ></div>
          ))}
        </div>
      </div> */}
      {/* Existing Grid View */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-6xl mb-12">
        {useCases.map((step) => (
          <div key={step.id} className="bg-neutral-card p-6 rounded-md shadow transition-transform transform hover:scale-105 cursor-default">
            <h3 className="text-xl font-semibold text-primary mb-3">{step.title}</h3>
            <p className="text-neutral-muted">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseCasesCarousel;
