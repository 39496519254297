import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const steps = [
  {
    id: 1,
    title: "Search",
    description: "Type in a place or historical event to begin your journey.",
  },
  {
    id: 2,
    title: "Discover",
    description: "See interactive maps and timelines that reveal key moments.",
  },
  {
    id: 3,
    title: "Explore",
    description: "Dive deeper into narratives, events, and places of interest.",
  },
];

const HowItWorksCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % steps.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-8 bg-neutral-light dark:bg-dark-background text-neutral-text dark:text-dark-text flex flex-col items-center">
      <h2 className="text-3xl font-bold mb-8">How It Works</h2>
      <div className="relative w-full max-w-4xl h-48 overflow-hidden rounded-lg shadow">
        <AnimatePresence mode="wait">
          <motion.div
            key={steps[currentIndex].id}
            className="absolute w-full h-full flex flex-col items-center justify-center bg-neutral-card dark:bg-dark-card p-6 rounded-lg"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.4 }}
          >
            <h3 className="text-xl font-semibold text-primary mb-2">
              {steps[currentIndex].title}
            </h3>
            <p className="text-neutral-muted dark:text-dark-muted text-center">
              {steps[currentIndex].description}
            </p>
          </motion.div>
        </AnimatePresence>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? "bg-primary" : "bg-neutral-muted dark:bg-dark-muted"
              }`}
            ></div>
          ))}
        </div>
      </div>
      {/* <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-6xl">
        {steps.map((step) => (
          <div key={step.id} className="bg-neutral-card p-6 rounded-md shadow">
            <h3 className="text-xl font-semibold text-primary mb-3">{step.title}</h3>
            <p className="text-neutral-muted">{step.description}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default HowItWorksCarousel;
